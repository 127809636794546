import React, { useMemo } from "react"
import { PageProps, graphql, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { MainLayout } from "@components/layouts"
import { Query } from "types/gatsby-graphql"
import { Seo } from "@components"
import { getExcerptText } from "@utils/blogUtils"

interface IProps extends PageProps {
  data: Query
}

const PostTemplate: React.VFC<IProps> = ({ data }) => {
  const { markdownRemark } = data
  const frontmatter = useMemo(() => markdownRemark?.frontmatter, [])
  const featuredImage = getImage(
    frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData
  )

  const excerpt = getExcerptText(markdownRemark?.html || "")

  return (
    <MainLayout>
      <Seo
        image={`${process.env.APP_URL}${featuredImage?.images.fallback?.src}`}
        title={frontmatter?.title || ""}
        description={excerpt}
        keywords={frontmatter?.keywords || ""}
      />
      <div className="blog-post-container">
        <div className="blog-post">
          <h1 className="title">{frontmatter?.title}</h1>
          <hr />
          <div
            className="blog-post-content content"
            dangerouslySetInnerHTML={{ __html: markdownRemark?.html || "" }}
          />
          <div className="tags-link">
            <ul>
              {frontmatter?.tags?.map((i, key) => (
                <li key={key}>
                  <Link to={`/tags/${i}`}>
                    <span className="tag is-info is-light is-medium">#{i}</span>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="is-clearfix"></div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export const pageQuery = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
        keywords
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default PostTemplate
